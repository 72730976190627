<template>
  <div>
    <as-header/>
    <router-view/>
  </div>
</template>

<script>
import AsHeader from '@/partials/Header'

export default {
  name: 'Primary',
  components: { AsHeader }
}
</script>

<style scoped>

</style>
