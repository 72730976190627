<template>
  <header class="masthead">
    <div class="container h-100">
      <div class="row">
        <div class="col-md-12">
          <h1 class="text-uppercase text-white font-header"
              style="text-shadow: 2px 2px #000;">{{ $t('tile.title') }}</h1>
          <hr class="divider">
          <p>{{ $t('tile.description') }}</p>
          <router-link class="btn btn-primary js-scroll-trigger"
                       :to="{name: 'login'}">
            {{ $t('button.login') }}
          </router-link>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Header'
}
</script>

<style scoped>
.masthead {
  height: 85vh;
}
header.masthead {
  padding-top: 10rem;
  padding-bottom: calc(10rem - 72px);
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(92, 77, 66, 0.8)), to(rgba(92, 77, 66, 0.8))), url("../../public/img/bg.jpg");
  background: linear-gradient(to bottom, rgba(92, 77, 66, 0.8) 0%, rgba(92, 77, 66, 0.8) 100%), url("../../public/img/bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
}

header.masthead h1 {
  font-size: 2.25rem;
}

@media (min-width: 992px) {
  header.masthead {
    height: 100vh;
    min-height: 40rem;
    padding-top: 72px;
    padding-bottom: 0;
  }
  header.masthead p {
    font-size: 1.15rem;
  }
  header.masthead h1 {
    font-size: 3rem;
  }
}

@media (min-width: 1200px) {
  header.masthead h1 {
    font-size: 3.5rem;
  }
}

hr.divider {
  max-width: 3.25rem;
  border-width: 0.2rem;
  border-color: var(--primary);
}

.container.h-100 {
  height: 85vh;
  display: flex;
  width: 100%;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #ffffff;
}

a {
  font-family: var(--primary-font);
}

p {
  font-size: 14px !important;
}
</style>
